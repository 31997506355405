<template>
    <div id="about_us" class="xl:container mx-auto font-sans lg:px-8 px-2 py-16 bg-[#1B1B1C] rounded-t-[48px] text-white">
        <div class="grid md:grid-cols-5 grid-cols-1 md:justify-items-center items-center md:m-0 ml-4">
            <div class="col-span-1 md:pb-0 pb-10 mr-4">
                <img class="md:w-42 w-72" src="../assets/logo_hamroh_black_white.svg" alt="">
            </div>
            <div class="md:col-span-2 md:m-0 my-4">
                <h4 class="md:text-3xl text-2xl font-bold">Междугородные сервисы</h4>
                <ul>
                    <li class="my-2 cursor-pointer">
                        <a href="https://hamroh.com/app/passenger/trips">Поездки водителей</a> 
                    </li>
                    <li class="my-2 cursor-pointer">
                        <a href="https://hamroh.com/app/performer/trips">Поездки пассажиров</a>
                    </li>
                    <!-- <li class="my-2 cursor-pointer">
                        Доставки грузов
                    </li> -->
                </ul>
            </div>
            <div class="md:m-0 my-4">
                <h4 class="md:text-3xl text-2xl font-bold "> Наш телеграм</h4>
                <ul>
                    <li class="my-2 cursor-pointer">
                        <a href="https://t.me/hamroh_gram">Телеграм канал</a>
                    </li>
                    <li class="my-2 cursor-pointer">
                        <a href="https://t.me/hamroh_gram_bot">Телеграм бот</a>
                    </li>
                    <li class="my-2 cursor-pointer">
                        <a href="https://t.me/Gram_tj">Администратор</a>
                    </li>
                </ul>
            </div>
            <div class="md:m-0 my-4">
                <h4 class="md:text-3xl text-2xl font-bold">Топ города</h4>
                <ul>
                    <li class="my-2 cursor-pointer">
                        Худжанд
                    </li>
                    <li class="my-2 cursor-pointer">
                        Душанбе
                    </li>
                    <li class="my-2 cursor-pointer">
                        Ойбек
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="xl:container mx-auto font-sans lg:px-8 px-2 py-16 bg-[#1B1B1C] text-white border-t">
        <ul class="grid md:grid-cols-3 grid-cols-2 justify-center text-center">
            <li class="mx-4">
                Авторские права 2024, Все права защищены.
            </li>
            <router-link to="/legal/ru?legal_id=3" @click="scrollToTop()"
                        class="hover:text-[#3d3a3a] lg:mt-0 mt-[20px] cursor-pointer">Условия использования
            </router-link>
            <router-link to="/legal" @click="scrollToTop()"
                        class="hover:text-[#3d3a3a] mr-[20px] cursor-pointer">Политика конфиденциальности 
            </router-link>
        </ul>
    </div>
</template>
<script>
import { useHead } from '@unhead/vue'
export default {
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0 })
    },
  },
  setup() {
    useHead({
      meta: [
        {
          name: 'description',
          content: 'Узнайте больше о сервисе Hamroh, который помогает находить попутчиков для междугородних поездок. Присоединяйтесь к сообществу и сделайте свои поездки удобнее.'
        },
        {
          name: 'keywords',
          content: 'поездки, попутчики, междугородние поездки, водители, пассажиры, сервис Hamroh'
        }
      ]
    })
  }
}
</script>
